import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email} from "vuelidate/lib/validators";
import Vue from 'vue';
import VueTelInput from "vue-tel-input";
import 'vue-tel-input/dist/vue-tel-input.css';
import 'vue-tel-input/dist/css/sprite.css'; // Flags styles
import 'vue-tel-input/dist/css/component.css'; // Component styles
Vue.use(VueTelInput);
export default {
  name: "register",
  mixins: [validationMixin],
  components:{
  },
  data(){
    return{
      see: true,
      payload: {
        login: '',
        password: '',
        last_name: '',
        name: '',
        patronymic: '',
        email: '',
        phone: '',
        country: '',
        city: ''
      },
      validationErrors: {},
      vueTel: {
        phone: '',
        props: {
          maxLen: 11,
          validCharactersOnly: true,
          mode: "international",
          dynamicPlaceholder: 'true',
          disabledFetchingCountry: true,
          disabled: false,
          required: true,
          enabledCountryCode: false,
          enabledFlags: true,
          onlyCountries: [],
          ignoredCountries: [],
          autocomplete: "off",
          name: "telephone",
          inputClasses: "",
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: true,
          wrapperClasses: "label"
        }
      },
    }
  },
  created() {

  },
  validations: {
    payload: {
      last_name: {
        maxLength: maxLength(25),
        minLength: minLength(2)
      },
      name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2),
      },
      patronymic: {
        maxLength: maxLength(25),
        minLength: minLength(2),
      },
      phone: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(7),
      },
      country: {
        maxLength: maxLength(30),
        minLength: minLength(2),
      },
      city: {
        maxLength: maxLength(30),
        minLength: minLength(2),
      },
      email: {
        email,
        maxLength: maxLength(100),
        required,
      },
      login: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(4),
      },
      password: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(8),
      },
    },
  },
  computed:{
    ...mapGetters({
      loadingRegistration: 'auth/loadingRegistration'
    }),
    nameError() {
      const error = []
      if (!this.$v.payload.name.$dirty) {
        return error
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    lastNameError() {
      const error = []
      if (!this.$v.payload.last_name.$dirty) {
        return error
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    patronymicError() {
      const error = []
      if (!this.$v.payload.patronymic.$dirty) {
        return error
      }
      if (!this.$v.payload.patronymic.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.patronymic.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.patronymic) {
        this.validationErrors.patronymic.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    phoneError() {
      const error = []
      if (!this.$v.payload.phone.$dirty) {
        return error
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 16))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 7))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('errorEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 100))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    countryError() {
      const error = []
      if (!this.$v.payload.country.$dirty) {
        return error
      }
      if (!this.$v.payload.country.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 30))
      }
      if (!this.$v.payload.country.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.country) {
        this.validationErrors.country.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    cityError() {
      const error = []
      if (!this.$v.payload.city.$dirty) {
        return error
      }
      if (!this.$v.payload.city.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 30))
      }
      if (!this.$v.payload.city.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    passwordError() {
      const error = []
      if (!this.$v.payload.password.$dirty) {
        return error
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 8))
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    loginError() {
      const error = []
      if (!this.$v.payload.login.$dirty) {
        return error
      }
      if (!this.$v.payload.login.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.login.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.login.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 4))
      }
      if (this.validationErrors.login) {
        this.validationErrors.login.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },

  },

  methods:{
    ...mapActions({
      register: `auth/REGISTRATION`
    }),
    ...mapMutations({
    }),
    submitForm() {
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        this.register(this.payload).then(() => {
          this.$toasted.success(this.$t('success'))
          this.$router.push({name: 'profile'}).catch(()=>{
            console.log()
          })
        }).catch(error => {
          if (error.status === 422) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for(const i in errors){
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      }
    }
  }
}